//4 FLAGS

import React from 'react';
import { Link } from 'react-router-dom'; // Make sure to import Link from react-router-dom
import './App.css'; 

function Game() {
  return (
    <div className="game-mode-selection">
      <Link to="/ClassicMode" className="game-mode-cell">
        <img src="pokeballs/pokeball.png" alt="Game Mode 1" /> 
        <p>Classic Mode</p>
      </Link>
      <Link to="/TimeTrial" className="game-mode-cell">
        <img src="pokeballs/great_ball.png" alt="Game Mode 2" />
        <p>Time Trials</p>
      </Link>
      <div className="game-mode-cell">
        <img src="pokeballs/ultra_ball.png" alt="Game Mode 3" />
        <p>SandBox Mode</p>
      </div>
      <div className="game-mode-cell">
        <img src="pokeballs/master_ball.png" alt="Game Mode 4" />
        <p>PokeFreak Challenge</p>
      </div>
    </div>
  );
}

export default Game;
