import React, { useState, useEffect } from 'react';
import { ref, onValue, off } from 'firebase/database';
import database from './firebase';

function Leaderboard() {
  const [activeTab, setActiveTab] = useState('classicMode');
  const [leaderboardData, setLeaderboardData] = useState([]);

  useEffect(() => {
    const scoresRef = ref(database, activeTab);
    onValue(scoresRef, (snapshot) => {
      const data = snapshot.val();
      let formattedData = data ? Object.values(data) : [];
    
      if (activeTab === 'TimeTrial') {
        formattedData.sort((a, b) => a.score - b.score);
      } else {
        formattedData.sort((a, b) => b.score - a.score);
      }

      setLeaderboardData(formattedData);
    });

    return () => off(scoresRef);
  }, [activeTab]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    const thousandths = Math.floor((time % 1) * 1000);
    return `${minutes}:${seconds.toString().padStart(2, '0')}.${thousandths.toString().padStart(3, '0')}`;
  };

  return (
    <div className="leaderboard-container">
      <div className="tabs">
        <button onClick={() => setActiveTab('classicMode')}>Classic Mode</button>
        <button onClick={() => setActiveTab('TimeTrial')}>Time Trial</button>
        {/* Add more tabs as needed */}
      </div>

      <h1 className="leaderboard-title">Leaderboard</h1>
      <ul className="leaderboard-list">
        <li className="leaderboard-header">
          <div>Name</div>
          <div>{activeTab === 'TimeTrial' ? 'Time' : 'Score'}</div>
          <div>Date</div>
        </li>
        {leaderboardData.map((entry, index) => (
          <li key={index} className="leaderboard-item">
            <div>{entry.name}</div>
            <div>{activeTab === 'TimeTrial' ? formatTime(entry.score) : entry.score}</div>
            <div>{new Date(entry.timestamp).toLocaleDateString('en-US')}</div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Leaderboard;
