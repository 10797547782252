import React, { useEffect } from 'react';
import { HashRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Game from './Game';
import TimeTrial from './TimeTrial';
import Leaderboard from './Leaderboard'; 
import HomePage from './HomePage';
import './App.css';
import ClassicMode from './ClassicMode';


function App() {


  return (
    <Router>
      <div>
        <nav>
          <ul>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/play">Play</Link></li>
            <li><Link to="/leaderboard">Leaderboard</Link></li>
          </ul>
        </nav>

        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/play" element={<Game />} />
          <Route path="/ClassicMode" element={<ClassicMode />} />
          <Route path="/TimeTrial" element={<TimeTrial />} />
          <Route path="/leaderboard" element={<Leaderboard />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
