const pokemonList = [
  "parasect",
	"sobble",
	"lumineon",
	"raikou",
	"runerigus",
	"dedenne",
	"pyroar",
	"pawmi",
	"articuno",
	"meowstic",
	"magmortar",
	"bulbasaur",
	"banette",
	"staraptor",
	"pidove",
	"morgrem",
	"comfey",
	"taillow",
	"charizard",
	"infernape",
	"sandshrew",
	"marshadow",
	"alakazam",
	"lickitung",
	"starmie",
	"wishiwashi",
	"yanmega",
	"yveltal",
	"zigzagoon",
	"petilil",
	"torterra",
	"purugly",
	"tandemaus",
	"cresselia",
	"regigigas",
	"palkia",
	"impidimp",
	"froakie",
	"kilowattrel",
	"arrokuda",
	"munna",
	"bellossom",
	"gabite",
	"tapu-koko",
	"mareep",
	"shieldon",
	"sneasler",
	"stoutland",
	"corviknight",
	"walking-wake",
	"accelgor",
	"makuhita",
	"seviper",
	"tirtouga",
	"lampent",
	"chi-yu",
	"ceruledge",
	"nidoking",
	"huntail",
	"nosepass",
	"carracosta",
	"nidoqueen",
	"inkay",
	"archeops",
	"avalugg",
	"grubbin",
	"sentret",
	"golbat",
	"skorupi",
	"conkeldurr",
	"jellicent",
	"yungoos",
	"meltan",
	"arctovish",
	"barraskewda",
	"luxio",
	"duraludon",
	"binacle",
	"galvantula",
	"crawdaunt",
	"hippopotas",
	"swirlix",
	"finneon",
	"kleavor",
	"medicham",
	"yamper",
	"poliwrath",
	"deoxys",
	"magnemite",
	"florges",
	"serperior",
	"coalossal",
	"shinx",
	"quaquaval",
	"budew",
	"shellos",
	"gothita",
	"overqwil",
	"porygon",
	"alomomola",
	"urshifu",
	"electrike",
	"dratini",
	"mienfoo",
	"reshiram",
	"sawsbuck",
	"blaziken",
	"flareon",
	"gliscor",
	"heatran",
	"gholdengo",
	"thievul",
	"beedrill",
	"butterfree",
	"tadbulb",
	"fletchinder",
	"tsareena",
	"ludicolo",
	"phanpy",
	"toxicroak",
	"cyndaquil",
	"swellow",
	"machop",
	"eternatus",
	"fletchling",
	"naganadel",
	"ariados",
	"mr-mime",
	"passimian",
	"frogadier",
	"roggenrola",
	"shroodle",
	"shedinja",
	"duosion",
	"scraggy",
	"gumshoos",
	"latias",
	"shelmet",
	"sawk",
	"pawmo",
	"venusaur",
	"azelf",
	"sinistea",
	"tornadus",
	"quaxwell",
	"obstagoon",
	"musharna",
	"drampa",
	"liepard",
	"aipom",
	"seaking",
	"arboliva",
	"xurkitree",
	"meditite",
	"heatmor",
	"chandelure",
	"ivysaur",
	"calyrex",
	"gible",
	"corphish",
	"octillery",
	"cubone",
	"crocalor",
	"dusclops",
	"koffing",
	"wo-chien",
	"gengar",
	"larvitar",
	"rampardos",
	"tyrogue",
	"wigglytuff",
	"oricorio",
	"castform",
	"quaxly",
	"dusknoir",
	"chinchou",
	"litleo",
	"floragato",
	"manectric",
	"grapploct",
	"ditto",
	"scrafty",
	"great-tusk",
	"kakuna",
	"gastrodon",
	"wingull",
	"flaaffy",
	"sunflora",
	"iron-jugulis",
	"gastly",
	"tarountula",
	"delcatty",
	"granbull",
	"iron-leaves",
	"whismur",
	"gourgeist",
	"cleffa",
	"growlithe",
	"sigilyph",
	"cascoon",
	"rapidash",
	"hydreigon",
	"cryogonal",
	"stunfisk",
	"stantler",
	"dracovish",
	"tapu-fini",
	"steenee",
	"slither-wing",
	"snubbull",
	"crabominable",
	"surskit",
	"ampharos",
	"monferno",
	"rhydon",
	"orthworm",
	"clodsire",
	"arbok",
	"amaura",
	"lucario",
	"hatterene",
	"pupitar",
	"elekid",
	"goomy",
	"vullaby",
	"venipede",
	"doublade",
	"bellibolt",
	"regieleki",
	"tangela",
	"pignite",
	"sandile",
	"brionne",
	"machamp",
	"wailord",
	"rhyhorn",
	"ninjask",
	"salandit",
	"grotle",
	"dragonair",
	"mime-jr",
	"zebstrika",
	"zarude",
	"pikachu",
	"typhlosion",
	"sizzlipede",
	"aromatisse",
	"chimecho",
	"diggersby",
	"trevenant",
	"audino",
	"dodrio",
	"azumarill",
	"perrserker",
	"aggron",
	"dialga",
	"tapu-bulu",
	"totodile",
	"tauros",
	"silcoon",
	"cottonee",
	"araquanid",
	"gallade",
	"tropius",
	"bouffalant",
	"wattrel",
	"frigibax",
	"woobat",
	"archen",
	"ho-oh",
	"scorbunny",
	"iron-valiant",
	"noibat",
	"wartortle",
	"chien-pao",
	"eevee",
	"mewtwo",
	"buneary",
	"sableye",
	"bidoof",
	"rookidee",
	"scovillain",
	"braixen",
	"elgyem",
	"gloom",
	"electabuzz",
	"piloswine",
	"miltank",
	"guzzlord",
	"cinccino",
	"greavard",
	"hattrem",
	"hatenna",
	"anorith",
	"wurmple",
	"shelgon",
	"toxapex",
	"swalot",
	"lurantis",
	"tyrunt",
	"pidgey",
	"quagsire",
	"gurdurr",
	"amoonguss",
	"mankey",
	"illumise",
	"cacturne",
	"tinkatink",
	"igglybuff",
	"maschiff",
	"qwilfish",
	"goldeen",
	"abra",
	"tinkatuff",
	"purrloin",
	"gulpin",
	"munchlax",
	"crobat",
	"volcanion",
	"lillipup",
	"xerneas",
	"lechonk",
	"lapras",
	"gyarados",
	"walrein",
	"sneasel",
	"lickilicky",
	"skuntank",
	"murkrow",
	"bibarel",
	"tentacool",
	"watchog",
	"oshawott",
	"nidoranf",
	"pidgeotto",
	"espurr",
	"houndoom",
	"nidorino",
	"hariyama",
	"milcery",
	"talonflame",
	"dustox",
	"mimikyu",
	"kommo-o",
	"weepinbell",
	"seel",
	"houndstone",
	"pyukumuku",
	"naclstack",
	"annihilape",
	"pineco",
	"cosmog",
	"kadabra",
	"swablu",
	"skitty",
	"regidrago",
	"masquerain",
	"minun",
	"heracross",
	"cursola",
	"trapinch",
	"girafarig",
	"tyrantrum",
	"graveler",
	"corvisquire",
	"poochyena",
	"electivire",
	"shiinotic",
	"melmetal",
	"ledian",
	"cherrim",
	"popplio",
	"sandy-shocks",
	"polteageist",
	"primeape",
	"glastrier",
	"vanillish",
	"aegislash",
	"probopass",
	"skiploom",
	"chesnaught",
	"kyurem",
	"drakloak",
	"carnivine",
	"torracat",
	"gardevoir",
	"pinsir",
	"snorunt",
	"samurott",
	"necrozma",
	"darmanitan",
	"charcadet",
	"smeargle",
	"cetoddle",
	"rellor",
	"inteleon",
	"magby",
	"ursaring",
	"crabrawler",
	"haxorus",
	"geodude",
	"foongus",
	"hippowdon",
	"toedscool",
	"ursaluna",
	"delibird",
	"oranguru",
	"drapion",
	"koraidon",
	"smoochum",
	"scyther",
	"stunky",
	"swadloon",
	"klefki",
	"vivillon",
	"wormadam",
	"honedge",
	"entei",
	"servine",
	"zubat",
	"umbreon",
	"reuniclus",
	"froslass",
	"pheromosa",
	"brute-bonnet",
	"magcargo",
	"boldore",
	"genesect",
	"larvesta",
	"meowscarada",
	"golett",
	"kartana",
	"voltorb",
	"regirock",
	"cherubi",
	"barboach",
	"wooper",
	"escavalier",
	"vileplume",
	"mismagius",
	"blastoise",
	"litten",
	"sealeo",
	"dreepy",
	"snivy",
	"keldeo",
	"snorlax",
	"zamazenta",
	"lopunny",
	"persian",
	"beartic",
	"gothorita",
	"giratina",
	"rattata",
	"starly",
	"bewear",
	"nymble",
	"houndour",
	"eelektrik",
	"appletun",
	"quilava",
	"whimsicott",
	"magikarp",
	"skeledirge",
	"drowzee",
	"squawkabilly",
	"claydol",
	"clamperl",
	"seedot",
	"dottler",
	"garchomp",
	"skiddo",
	"hypno",
	"weedle",
	"garganacl",
	"greedent",
	"stakataka",
	"ambipom",
	"spoink",
	"pikipek",
	"frillish",
	"cutiefly",
	"honchkrow",
	"tympole",
	"ting-lu",
	"meganium",
	"incineroar",
	"landorus",
	"lileep",
	"pachirisu",
	"iron-moth",
	"tyranitar",
	"magnezone",
	"beldum",
	"glameow",
	"dondozo",
	"swanna",
	"primarina",
	"nuzleaf",
	"zangoose",
	"gogoat",
	"jolteon",
	"moltres",
	"dragonite",
	"golurk",
	"metapod",
	"golisopod",
	"marowak",
	"gigalith",
	"emboar",
	"exeggutor",
	"magneton",
	"mesprit",
	"groudon",
	"wobbuffet",
	"delphox",
	"exploud",
	"dracozolt",
	"mantyke",
	"malamar",
	"simisage",
	"espeon",
	"paras",
	"noctowl",
	"hitmonlee",
	"slaking",
	"solrock",
	"pelipper",
	"komala",
	"poliwhirl",
	"palpitoad",
	"eelektross",
	"patrat",
	"spheal",
	"seadra",
	"lotad",
	"flutter-mane",
	"ralts",
	"registeel",
	"slurpuff",
	"arcanine",
	"fomantis",
	"smoliv",
	"eiscue",
	"tangrowth",
	"aerodactyl",
	"pincurchin",
	"bronzor",
	"sylveon",
	"kyogre",
	"sharpedo",
	"cufant",
	"flabebe",
	"quilladin",
	"feebas",
	"metagross",
	"nidoranm",
	"maractus",
	"muk",
	"empoleon",
	"chimchar",
	"sandaconda",
	"staryu",
	"scream-tail",
	"pancham",
	"dwebble",
	"bruxish",
	"joltik",
	"armaldo",
	"grimmsnarl",
	"phione",
	"frosmoth",
	"rhyperior",
	"pansear",
	"alcremie",
	"happiny",
	"spearow",
	"helioptile",
	"kingambit",
	"fearow",
	"wooloo",
	"prinplup",
	"diancie",
	"rabsca",
	"poipole",
	"klink",
	"farfetchd",
	"gorebyss",
	"iron-bundle",
	"noivern",
	"herdier",
	"tynamo",
	"whiscash",
	"diglett",
	"wyrdeer",
	"krokorok",
	"scizor",
	"nihilego",
	"skarmory",
	"remoraid",
	"shaymin",
	"hitmontop",
	"snom",
	"cosmoem",
	"miraidon",
	"slowbro",
	"numel",
	"glalie",
	"vaporeon",
	"porygon-z",
	"lunatone",
	"machoke",
	"mareanie",
	"bastiodon",
	"mandibuzz",
	"arceus",
	"throh",
	"abomasnow",
	"bronzong",
	"flittle",
	"greninja",
	"hoppip",
	"kecleon",
	"crustle",
	"shuppet",
	"stonjourner",
	"gossifleur",
	"ducklett",
	"plusle",
	"poliwag",
	"treecko",
	"torchic",
	"kabutops",
	"dunsparce",
	"flygon",
	"morpeko",
	"spectrier",
	"orbeetle",
	"dugtrio",
	"marill",
	"magearna",
	"toxtricity",
	"silvally",
	"cacnea",
	"slugma",
	"oddish",
	"jirachi",
	"veluza",
	"ribombee",
	"glimmora",
	"litwick",
	"pidgeot",
	"sandslash",
	"drilbur",
	"cranidos",
	"chansey",
	"blacephalon",
	"camerupt",
	"arctozolt",
	"dudunsparce",
	"pichu",
	"duskull",
	"palafin",
	"kangaskhan",
	"chingling",
	"whirlipede",
	"silicobra",
	"ferroseed",
	"toucannon",
	"barbaracle",
	"salamence",
	"zekrom",
	"haunter",
	"glaceon",
	"golduck",
	"milotic",
	"politoed",
	"absol",
	"mienshao",
	"sprigatito",
	"aron",
	"tepig",
	"klang",
	"zapdos",
	"clauncher",
	"riolu",
	"skwovet",
	"raichu",
	"varoom",
	"hitmonchan",
	"slakoth",
	"ponyta",
	"togepi",
	"snover",
	"dubwool",
	"solosis",
	"fraxure",
	"caterpie",
	"iron-hands",
	"gimmighoul",
	"psyduck",
	"teddiursa",
	"tapu-lele",
	"piplup",
	"flamigo",
	"nickit",
	"darumaka",
	"finizen",
	"weavile",
	"mawile",
	"sewaddle",
	"unfezant",
	"buzzwole",
	"rockruff",
	"manaphy",
	"bunnelby",
	"seismitoad",
	"simipour",
	"vanilluxe",
	"cubchoo",
	"mantine",
	"maushold",
	"basculin",
	"dartrix",
	"weezing",
	"corsola",
	"roserade",
	"carbink",
	"heliolisk",
	"omanyte",
	"rufflet",
	"zygarde",
	"mothim",
	"klinklang",
	"sliggoo",
	"raticate",
	"grookey",
	"carkol",
	"dhelmise",
	"vibrava",
	"wiglett",
	"shiftry",
	"swampert",
	"excadrill",
	"brambleghast",
	"morelull",
	"fuecoco",
	"chatot",
	"klawf",
	"jigglypuff",
	"enamorus",
	"loudred",
	"forretress",
	"togetic",
	"feraligatr",
	"bisharp",
	"lycanroc",
	"iron-treads",
	"mabosstiff",
	"vikavolt",
	"victreebel",
	"decidueye",
	"cinderace",
	"spinarak",
	"onix",
	"wugtrio",
	"aurorus",
	"venonat",
	"thundurus",
	"wimpod",
	"nidorina",
	"tranquill",
	"farigiraf",
	"buizel",
	"mightyena",
	"vulpix",
	"clawitzer",
	"revavroom",
	"lilligant",
	"togedemaru",
	"minccino",
	"roaring-moon",
	"charjabug",
	"doduo",
	"ferrothorn",
	"misdreavus",
	"kingdra",
	"shuckle",
	"rayquaza",
	"palossand",
	"spidops",
	"mudbray",
	"kricketune",
	"skrelp",
	"luxray",
	"ledyba",
	"baltoy",
	"basculegion",
	"trumbeak",
	"dewpider",
	"applin",
	"sandygast",
	"yanma",
	"kubfu",
	"lugia",
	"turtonator",
	"hawlucha",
	"leavanny",
	"vespiquen",
	"solgaleo",
	"squirtle",
	"slowking",
	"exeggcute",
	"emolga",
	"carvanha",
	"zacian",
	"grafaiai",
	"clefairy",
	"bayleef",
	"drifloon",
	"stufful",
	"sceptile",
	"azurill",
	"chespin",
	"jangmo-o",
	"panpour",
	"meloetta",
	"glimmet",
	"staravia",
	"linoone",
	"tinkaton",
	"spiritomb",
	"zoroark",
	"chikorita",
	"vanillite",
	"scatterbug",
	"spinda",
	"beheeyem",
	"grimer",
	"pawmot",
	"leafeon",
	"indeedee-male",
	"cyclizar",
	"deerling",
	"celebi",
	"breloom",
	"turtwig",
	"zweilous",
	"burmy",
	"dolliv",
	"copperajah",
	"swinub",
	"axew",
	"dragapult",
	"pangoro",
	"darkrai",
	"krabby",
	"dewgong",
	"eldegoss",
	"sirfetchd",
	"volcarona",
	"vigoroth",
	"bombirdier",
	"falinks",
	"ninetales",
	"pawniard",
	"rolycoly",
	"blitzle",
	"porygon2",
	"croagunk",
	"xatu",
	"lairon",
	"tentacruel",
	"toedscruel",
	"krookodile",
	"gothitelle",
	"regice",
	"goodra",
	"thwackey",
	"meowth",
	"grovyle",
	"volbeat",
	"unown",
	"terrakion",
	"togekiss",
	"kricketot",
	"zeraora",
	"bellsprout",
	"luvdisc",
	"garbodor",
	"horsea",
	"nincada",
	"dragalge",
	"electrode",
	"salazzle",
	"fennekin",
	"metang",
	"donphan",
	"jynx",
	"yamask",
	"druddigon",
	"kabuto",
	"magmar",
	"swoobat",
	"braviary",
	"iron-thorns",
	"charmander",
	"baxcalibur",
	"rowlet",
	"lombre",
	"durant",
	"golem",
	"sunkern",
	"chewtle",
	"dewott",
	"croconaw",
	"clobbopus",
	"cobalion",
	"pansage",
	"deino",
	"spewpa",
	"floette",
	"combee",
	"flapple",
	"cofagrigus",
	"drednaw",
	"furfrou",
	"furret",
	"clefable",
	"wailmer",
	"grumpig",
	"charmeleon",
	"phantump",
	"venomoth",
	"natu",
	"boltund",
	"simisear",
	"blissey",
	"spritzee",
	"virizion",
	"armarouge",
	"scolipede",
	"rillaboom",
	"lokix",
	"fidough",
	"mamoswine",
	"minior",
	"mr-rime",
	"kirlia",
	"capsakid",
	"omastar",
	"drizzile",
	"bramblin",
	"hoopa",
	"steelix",
	"rotom",
	"hoothoot",
	"karrablast",
	"torkoal",
	"cramorant",
	"victini",
	"cloyster",
	"espathra",
	"relicanth",
	"wynaut",
	"raboot",
	"zorua",
	"blipbug",
	"timburr",
	"drifblim",
	"altaria",
	"cradily",
	"mudkip",
	"toxel",
	"lunala",
	"mudsdale",
	"shellder",
	"jumpluff",
	"bounsweet",
	"trubbish",
	"beautifly",
	"bagon",
	"oinkologne",
	"celesteela",
	"centiskorch",
	"marshtomp",
	"uxie",
	"suicune",
	"pumpkaboo",
	"sudowoodo",
	"bergmite",
	"lanturn",
	"kingler",
	"shroomish",
	"gligar",
	"roselia",
	"bonsly",
	"tatsugiri",
	"type-null",
	"dachsbun",
	"combusken",
	"arctibax",
	"slowpoke",
	"nacli",
	"floatzel",
	"latios",
	"mew",
	"ekans",
	"cetitan",
	"hakamo-o"
];

export default pokemonList;
